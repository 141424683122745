// https://github.com/dkfbasel/vuex-i18n/issues/16
import i18n from '@/plugins/i18n'
import compassApi from '@/services/compass-api'

const arrayToCsv = (arr) => {
  let line = []
  for (let i = 0; i < arr.length; ++i) {
    let item = arr[i]
    if (item && item.indexOf && (item.indexOf(',') !== -1 || item.indexOf('"') !== -1)) {
      item = '"' + item.replace(/"/g, '""') + '"'
    }
    line.push(item)
  }
  return line.join(';')
}

// https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link/19328891#19328891
// https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript
const saveCsv = (data, filename) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  let blob = new Blob(['\ufeff' + data], { type: 'text/csv' })
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

const exportBuildingJson = async (building, withGis) => {
  const exportData = await compassApi.call(`/buildings/${building.id}/export?gis=${withGis}`)
  saveJson(exportData, `${building.identifier}.json`)
}

const exportPortfolioBuildingsJson = async (portfolio, query, withGis) => {
  const queryString = JSON.stringify(query)
  const exportData = await compassApi.call(`/portfolios/${portfolio.id}/buildings/export?gis=${withGis}&query=${queryString}`)
  saveJson(exportData, `${portfolio.name}_buildings.json`)
}

const exportPortfolioJson = async (portfolio, withGis) => {
  const exportData = await compassApi.call(`/portfolios/${portfolio.id}/export?gis=${withGis}`)
  saveJson(exportData, `${portfolio.name}.json`)
}

const saveJson = (data, filename) => {
  const stringData = JSON.stringify(data, null, 2)
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const blob = new Blob([stringData], { type: 'text/plain' })
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

const chartToCsv = (chartData, chartOptions) => {
  const xAxesLabel = chartOptions.scales && chartOptions.scales.xAxes && chartOptions.scales.xAxes[0].scaleLabel ? chartOptions.scales.xAxes[0].scaleLabel.labelString : null
  const yAxesLabel = chartOptions.scales && chartOptions.scales.yAxes && chartOptions.scales.yAxes[0].scaleLabel ? chartOptions.scales.yAxes[0].scaleLabel.labelString : null
  let header = [xAxesLabel]
  for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
    const columnLabel = chartData.datasets[datasetIdx].label
    header.push(`${columnLabel} - ${yAxesLabel}`)
  }
  let rows = []
  for (let rowIdx = 0; rowIdx < chartData.labels.length; rowIdx++) {
    const rowLabel = chartData.labels[rowIdx]
    let rowValues = [rowLabel]
    for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
      const rowValue = chartData.datasets[datasetIdx].data[rowIdx]
      rowValues.push(rowValue)
    }
    rows.push(arrayToCsv(rowValues))
  }
  return [
    arrayToCsv(header),
    ...rows
  ]
}

const pieChartToCsv = (chartData, groupingName) => {
  let header = [groupingName]
  for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
    const columnLabel = chartData.datasets[datasetIdx].label
    header.push(columnLabel)
  }
  let rows = []
  for (let rowIdx = 0; rowIdx < chartData.labels.length; rowIdx++) {
    const rowLabel = chartData.labels[rowIdx]
    let rowValues = [rowLabel]
    for (let datasetIdx = 0; datasetIdx < chartData.datasets.length; datasetIdx++) {
      const rowValue = chartData.datasets[datasetIdx].data[rowIdx]
      rowValues.push(rowValue)
    }
    rows.push(arrayToCsv(rowValues))
  }
  return [
    arrayToCsv(header),
    ...rows
  ]
}

// TODO: Translate headers with i18n
const buildingCsvHeader = (scenarios) => {
  let header = [
    'id',
    'Nr',
    'Beschreibung',
    'Koordinaten (Longitude)',
    'Koordinaten (Latitude)',
    'PLZ',
    'Gemeinde',
    'Kanton',
    'Baujahr',
    'Hauptwärmeerzeuger',
    'Wärmeerzeugung Raumwärme',
    'Wärmeerzeugung Warmwasser',
    'Hauptnutzung',
    'Nutzungen',
    'EBF total (m2)',
    'HNF total (m2)',
    'EBF Datenverfügbarkeit',
    'Baujahr Heizung',
    'Nächster Heizungsersatz',
    'Sanierung Fassade',
    'Nächste Sanierung Fassade',
    'Baujahr Fenster',
    'Nächster Fensterersatz',
    'Sanierung Dach',
    'Nächste Sanierung Dach',
    'Sanierung Kellerdecke',
    'Nächste Sanierung Kellerdecke',
    'Scope 1-Emissionen (kg/m2)',
    'Scope 2-Emissionen (kg/m2)',
    'Primärenergie (kWh/m2)',
    'Primärenergie Referenz (kWh/m2)',
    'Strombedarf (kWh/m2)',
    'Mieterstrombedarf (kWh/m2)',
    'Allgemeinstrombedarf (kWh/m2)',
    'Raumwärme (kWh/m2)',
    'Raumwärme Referenz (kWh/m2)',
    'Korrektur Raumwärme',
    'Raumwärme Endenergie (kWh/m2)',
    'Warmwasser (kWh/m2)',
    'Korrektur Warmwasser',
    'Warmwasser Endenergie (kWh/m2)',
    'Leistungsbedarf Raumwärme/Warmwasser (kW)',
    'Endenergie Wärme total (kWh)',
    'Leistung PV (kWp)',
    'Ertrag PV (kWh/kWp)',
    'Eigenverbrauch PV (%)',
    'Erdwärmesonden',
    'Grundwassernutzung',
    'Fernwärme',
    'Dach Kl. 1 (m2)',
    'Dach Kl. 2 (m2)',
    'Dach Kl. 3 (m2)',
    'Dach Kl. 4 (m2)',
    'Dach Kl. 5 (m2)',
    'Dach Kl. 1 (kWh)',
    'Dach Kl. 2 (kWh)',
    'Dach Kl. 3 (kWh)',
    'Dach Kl. 4 (kWh)',
    'Dach Kl. 5 (kWh)',
    'Fassade Kl. 1 (m2)',
    'Fassade Kl. 2 (m2)',
    'Fassade Kl. 3 (m2)',
    'Fassade Kl. 4 (m2)',
    'Fassade Kl. 5 (m2)',
    'Fassade Kl. 1 (kWh)',
    'Fassade Kl. 2 (kWh)',
    'Fassade Kl. 3 (kWh)',
    'Fassade Kl. 4 (kWh)',
    'Fassade Kl. 5 (kWh)'
  ]
  let sortedScenarios = [...scenarios].sort((a, b) => a.id < b.id)
  for (let scenarioIdx = 0; scenarioIdx < sortedScenarios.length; scenarioIdx++) {
    const scenario = sortedScenarios[scenarioIdx]
    header.push(
      `${scenario.description} - Heizung`,
      `${scenario.description} - PV`,
      `${scenario.description} - Fassade`,
      `${scenario.description} - Fenster`,
      `${scenario.description} - Dach`,
      `${scenario.description} - Kellerdecke`,
      `${scenario.description} - Scope 1-Emissionen (kg/m2)`,
      `${scenario.description} - Scope 2-Emissionen (kg/m2)`,
      `${scenario.description} - Primärenergie (kWh/m2)`,
      `${scenario.description} - Strombedarf (kWh/m2)`,
      `${scenario.description} - Kosten Heizungsersatz (CHF)`,
      `${scenario.description} - Kosten PV (CHF)`,
      `${scenario.description} - Kosten Fassade (CHF)`,
      `${scenario.description} - Kosten Fenster (CHF)`,
      `${scenario.description} - Kosten Dach (CHF)`,
      `${scenario.description} - Kosten Kellerdecke (CHF)`,
      `${scenario.description} - Typ Heizung`,
      `${scenario.description} - Leistungsbedarf Raumwärme/Warmwasser (kW)`,
      `${scenario.description} - Endenergie Wärme total (kWh)`,
      `${scenario.description} - Leistung PV (kWp)`,
      `${scenario.description} - Ertrag PV (kWh/kWp)`,
      `${scenario.description} - Eigenverbrauch PV (%)`,
      `${scenario.description} - Rendite PV (%)`,
      `${scenario.description} - Rückzahldauer PV (Jahre)`
    )
  }
  return arrayToCsv(header)
}
function formatFeasibility (f) {
  if (f) {
    return i18n.tc(`_buildingTags.feasibility.${f}`)
  } else {
    return null
  }
}
function usageSummary (building) {
  let zonesText = []
  for (let zoneIdx = 0; zoneIdx < building.zones.length; zoneIdx++) {
    const zone = building.zones[zoneIdx]
    const zoneName = i18n.tc(`_usageTypes.${zone.usage}`)
    const zoneShare = zone.energy_area !== null && building.energy_area !== null && Math.round(zone.energy_area / building.energy_area * 100)
    if (Number.isNaN(zoneShare) || zoneShare === false) {
      zonesText.push(zoneName)
    } else {
      zonesText.push(`${zoneShare} % ${zoneName}`)
    }
  }
  return zonesText.join(', ')
}
function formatHeater (h, useType) {
  const typeString = i18n.tc(`_heatingTypes.${h.type}`)
  if (useType === 'rh') {
    if (h.rh_share < 100) {
      return `${typeString} (${h.rh_share}%)`
    } else {
      return typeString
    }
  }
  if (useType === 'dhw') {
    if (h.dhw_share < 100) {
      return `${typeString} (${h.dhw_share}%)`
    } else {
      return typeString
    }
  }
}
const buildingToCsv = (building) => {
  let data = [
    building.id,
    building.identifier,
    building.description,
    building.longitude,
    building.latitude,
    building.zip_code,
    building.municipality,
    building.tags.canton,
    building.building_year,
    i18n.tc(`_heatingTypes.${building.heating_type}`),
    building.heating.filter(h => h.rh_share > 0).map(h => formatHeater(h, 'rh')).join(', '),
    building.heating.filter(h => h.dhw_share > 0).map(h => formatHeater(h, 'dhw')).join(', '),
    i18n.tc(`_usageTypes.${building.main_usage}`),
    usageSummary(building),
    building.energy_area,
    building.rental_area,
    i18n.tc(`_dataQuality.${building.data_quality.energy_area}`),
    building.heating_year,
    building.heating_replacement_year,
    building.walls_year,
    building.walls_replacement_year,
    building.windows_year,
    building.windows_replacement_year,
    building.roof_year,
    building.roof_replacement_year,
    building.basement_year,
    building.basement_replacement_year,
    building.ghg_s1 ? (building.ghg_s1 / building.energy_area).toFixed(1) : null,
    building.ghg ? (building.ghg / building.energy_area).toFixed(1) : null,
    building.pe ? (building.pe / building.energy_area).toFixed(1) : null,
    building.pe_ref ? building.pe_ref.toFixed(1) : null,
    building.el_demand ? (building.el_demand / building.energy_area).toFixed(1) : null,
    building.el_tenant ? (building.el_tenant / building.energy_area).toFixed(1) : null,
    building.el_general ? (building.el_general / building.energy_area).toFixed(1) : null,
    building.rh_demand ? (building.rh_demand / building.energy_area).toFixed(1) : null,
    building.rh_limit ? building.rh_limit.toFixed(1) : null,
    building.rh_calibration ? building.rh_calibration.toFixed(3) : null,
    building.rh_end_energy ? (building.rh_end_energy / building.energy_area).toFixed(1) : null,
    building.dhw_demand ? (building.dhw_demand / building.energy_area).toFixed(1) : null,
    building.dhw_calibration ? building.dhw_calibration.toFixed(3) : null,
    building.dhw_end_energy ? (building.dhw_end_energy / building.energy_area).toFixed(1) : null,
    building.heating_power ? building.heating_power.toFixed(1) : null,
    building.dhw_end_energy && building.rh_end_energy ? (building.rh_end_energy + building.dhw_end_energy) : null,
    building.pv_power ? building.pv_power.toFixed(1) : null,
    building.el_production ? (building.el_production / building.pv_power).toFixed(1) : null,
    building.el_ownuse ? (building.el_ownuse / building.el_production * 100).toFixed(0) : null,
    formatFeasibility(building.tags.feasibility_ews),
    formatFeasibility(building.tags.feasibility_gw),
    formatFeasibility(building.tags.availability_district),
    building.roof_stats[0]['area'],
    building.roof_stats[1]['area'],
    building.roof_stats[2]['area'],
    building.roof_stats[3]['area'],
    building.roof_stats[4]['area'],
    building.roof_stats[0]['irradiation'],
    building.roof_stats[1]['irradiation'],
    building.roof_stats[2]['irradiation'],
    building.roof_stats[3]['irradiation'],
    building.roof_stats[4]['irradiation'],
    building.fassade_stats[0]['area'],
    building.fassade_stats[1]['area'],
    building.fassade_stats[2]['area'],
    building.fassade_stats[3]['area'],
    building.fassade_stats[4]['area'],
    building.fassade_stats[0]['irradiation'],
    building.fassade_stats[1]['irradiation'],
    building.fassade_stats[2]['irradiation'],
    building.fassade_stats[3]['irradiation'],
    building.fassade_stats[4]['irradiation']
  ]
  if (building.measure_totals) {
    let sortedMeasureTotals = [...building.measure_totals].sort((a, b) => a.scenario_id < b.scenario_id)
    for (let scenarioIdx = 0; scenarioIdx < sortedMeasureTotals.length; scenarioIdx++) {
      const measureTotals = sortedMeasureTotals[scenarioIdx]
      data.push(
        measureTotals.heating_year,
        measureTotals.pv_year,
        measureTotals.walls_year,
        measureTotals.windows_year,
        measureTotals.roof_year,
        measureTotals.basement_year,
        (measureTotals.ghg_s1 / building.energy_area).toFixed(1),
        (measureTotals.ghg / building.energy_area).toFixed(1),
        (measureTotals.pe / building.energy_area).toFixed(1),
        (measureTotals.el_demand / building.energy_area).toFixed(1),
        measureTotals.total_costs.heating,
        measureTotals.total_costs.pv,
        measureTotals.total_costs.walls,
        measureTotals.total_costs.windows,
        measureTotals.total_costs.roof,
        measureTotals.total_costs.basement,
        i18n.tc(`_heatingTypes.${measureTotals.heating_type}`),
        measureTotals.heating_power,
        measureTotals.dhw_end_energy && measureTotals.rh_end_energy ? (measureTotals.rh_end_energy + measureTotals.dhw_end_energy) : null,
        measureTotals.pv_power ? measureTotals.pv_power.toFixed(1) : null,
        measureTotals.el_production ? (measureTotals.el_production / measureTotals.pv_power).toFixed(1) : null,
        measureTotals.el_ownuse ? (measureTotals.el_ownuse / measureTotals.el_production * 100).toFixed(0) : null,
        measureTotals.pv_yield_rate ? measureTotals.pv_yield_rate.toFixed(1) : null,
        measureTotals.pv_break_even ? measureTotals.pv_break_even.toFixed(1) : null
      )
    }
  }
  return arrayToCsv(data)
}

const exportService = {
  buildingCsvHeader,
  buildingToCsv,
  chartToCsv,
  pieChartToCsv,
  saveCsv,
  saveJson,
  exportBuildingJson,
  exportPortfolioJson,
  exportPortfolioBuildingsJson
}

export default exportService
